import React, { Component } from 'react';
import { ReactSketchCanvas, } from "react-sketch-canvas";
import Lottie from 'react-lottie';

import styles from "components/ClientContentStyles.module.scss";
import "animate.css";
import familyIcon from "images/Family Icon.png"
import adultsIcon from "images/Adults Icon.png"
import logoImg from "images/scrawl-logo.png";
import logo17 from "images/scrawl-logo-17.png";
import getAvatarById from "constants/avatars";

import { saveAs } from 'file-saver';
import ErrorModal from './Utility/ErrorModal';

const animations = [
    "bounce",
    "rubberBand",
    "shakeX",
    "shakeY",
    "headShake",
    "swing",
    "tada",
    "wobble",
    "jello",
    "heartBeat",
];

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    ChoosingCards: "choosing_cards",
    Playing: "playing",
    Revealing: "revealing",
    Awarding: "awarding",
    Idle: "idle",
    Results: "results",
    EndGame: "end_game",
};


export default class ClientContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            contentFilter: props.contentFilter,
            canvasColor: "white",
            gifButtonEnabled: true,
            generateGIF: true,
            gif: null,
            isSubmitting: false,
            gameState: null,
            tickedSkipTutorial: false,
            showStartWarning: false,
            showContentWarning: false,
        };
        this.canvas = React.createRef();
        this.doodleInterval = null;
    }

    componentDidMount() {
        this.props.room.state.scrawlData.onChange(() => {

        });
        this.props.room.state.scrawlData.listen("gameState", (value) => {
            if (value !== GameStates.Loading && value != GameStates.EndGame && this.state.showStartWarning) {
                this.setState({ showStartWarning: false });
            }

            this.setState({ gameState: value });
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.gif != null) {
            var image = nextProps.gif;
            var link = document.getElementById("gifButton");
            var name = nextProps.gifName;

            if (link) {
                //link.setAttribute('href', image)
                ////link.setAttribute('href', logo);
                //link.setAttribute('download', `${name}.gif`);
                //link.setAttribute('target', '_blank');
                link.onclick = () => this.downloadGIF(image, name);
            }

        }

        this.setState({
            player: nextProps.player,
        });
    }

    downloadGIF = (image, name = "scrawlGif") => {
        saveAs(image, `${name}.gif`);
        //if (this.state.gifButtonEnabled) {
        //    this.setState({ gifButtonEnabled: true, });
        //}
    };

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });


    doAnimation = () => {
        let animation = this.getRandomElement(animations);
        this.state.room.send("animate_potato", { animation: animation, });
        if (document.getElementById(`potato`)) {
            this.animateCSS(`#potato`, animation);
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    sendDoodle() {
        if (this.canvas.current) {
            this.setState({ canvasColor: "rgba(0,0,0,0)", isSubmitting: true, }, () => {
                this.canvas.current.exportImage("png").then(data => {
                    this.props.room.send("submit_doodle", { doodleURL: data, });
                    clearInterval(this.doodleInterval);
                    this.doodleInterval = null;
                    if (this.canvas.current) {
                        this.canvas.current.resetCanvas();
                    }
                    this.setState({ canvasColor: "white", isSubmitting: false, });
                });
            });
        }
    }

    clickStartGame = (confirm = false, confirmContentWarning = false) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }


        if (!confirmContentWarning && this.state.contentFilter === 1) {
            this.setState({ showContentWarning: true });
        }
        else if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }

    clickGoToLobby = () => {
        this.state.room.send("change_game", {});
    }

    clickPlayAgain = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("new_game", {});
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    closeContentWarning = () => {
        this.setState({ showContentWarning: false });
    }

    confirmContentWarning = () => {
        this.clickStartGame(false, true);
        this.setState({ showContentWarning: false });
    }

    toggleSkipTutorial = (e) => {
        console.log("cb value: " + e.target.checked);
        this.setState({ tickedSkipTutorial: e.target.checked });
    }

    confirmStartGame = () => {
        this.clickStartGame(true);
    }

    confirmPlayAgain = () => {
        this.clickPlayAgain(true);
    }

    changeContentFilter(value, send = true) {
        this.setState({ contentFilter: value });
        if (send) this.state.room.send("toggle_content_filter", { value: value });
        const userFriendlyValues = ["Family", "Adult"]
        console.log("changed content filter to: " + userFriendlyValues[value]);
    }

    checkIsPrimary() {
        return this.state.player && this.state.player.primaryPlayer && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) && this.props.hostConnected;
    }

    render() {
        return (
            <div className={styles.defaultSection}>
                {!this.props.isRevealing && this.checkIsPrimary() &&
                    <React.Fragment>
                        <div className={styles.logoBox}>
                            <img src={logoImg} className={`${styles.logo} ${styles.primary} ${this.props.contentFilter !== 0 && styles.show}`} alt="logo" />
                            <img src={logo17} className={`${styles.logo17} ${this.state.contentFilter === 1 && styles.show}`} alt="17+" />
                        </div>
                        <div className={styles.playerSection}>
                            {
                                this.state.player &&
                                <React.Fragment>
                                    <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                                        <Lottie
                                            options={getAvatarById(this.state.player.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                    <div className={styles.text}>{this.state.player.name}</div>
                                </React.Fragment>
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    this.props.title && this.props.title.length > 0 &&
                    <div className={styles.title}>{this.props.title}</div>
                }
                {
                    !this.props.isRevealing && !this.checkIsPrimary() ?
                        <div className={styles.doodleSection}>
                            <div className={styles.doodleCanvasContainer}>
                                <div className={styles.doodleText}>DOODLE WHILE<br />YOU WAIT</div>
                                <div className={styles.doodleBacker}></div>
                                <ReactSketchCanvas
                                    ref={this.canvas}
                                    style={{ zIndex: 2, boxShadow: "4px 4px 10px 1px rgba(0,0,0,0.3)", }}
                                    canvasColor={this.state.canvasColor}
                                    strokeColor="black"
                                    strokeWidth="5"
                                    id="sketchCanvas"
                                    width={this.props.canvasSize}
                                    height={this.props.canvasSize}
                                />
                            </div>
                            <div className={styles.sendButtonWrapper}>
                                <div className={styles.button} onClick={() => this.sendDoodle()}>
                                    {
                                        this.state.isSubmitting ?
                                            <div className={styles.loading}></div>
                                            :
                                            "Send"
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        this.props.content && this.props.content.length > 0 &&
                        <div className={styles.content}>{this.props.content}</div>
                }
                {
                    this.props.showGifButton ?
                        <div className={styles.contentSection}>
                            <a id='gifButton' className={`${styles.button} ${this.state.gifButtonEnabled ? "" : styles.disabled}`}>Download GIF</a>
                        </div>
                        :
                        null
                    //<Loading loadingText={"Creating GIF..."} noBg={true} hideLoader={false} />
                }
                {
                    this.checkIsPrimary() &&
                    <React.Fragment>
                        {
                            this.state.gameState === GameStates.Loading &&
                            <div className={styles.buttons}>
                                {
                                    this.props.availableAddons.includes("scrawl_adult") && <div className={styles.contentFilter}>
                                        <input type="range" min="0" max="1" value={this.state.contentFilter} className={styles.bar} id="filter" step="1" onChange={(e) => this.changeContentFilter(Number(e.target.value))} />
                                        <div className={styles.icons}>
                                            <img src={familyIcon} alt="familyIcon" onClick={() => this.changeContentFilter(0, true)} />
                                            <img src={adultsIcon} alt="adultsIcon" onClick={() => this.changeContentFilter(1, true)} />
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.button} ${styles.controls}`} onClick={() => this.clickStartGame()}>Start Game</div>
                                <div className={`${styles.button} ${styles.controls}`} onClick={this.clickGoToLobby}>Go To Lobby</div>
                                <div className={styles.skipBox}>
                                    <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                    <label for="checkbox">Skip Tutorial</label>
                                </div>
                            </div>
                        }
                        {
                            this.state.gameState === GameStates.EndGame &&
                            <div className={styles.buttons}>
                                <div className={`${styles.button} ${styles.controls}`} onClick={() => this.clickPlayAgain()}>Play Again</div>
                                <div className={`${styles.button} ${styles.controls}`} onClick={this.clickGoToLobby}>Different Game</div>
                            </div>
                        }
                    </React.Fragment>
                }
                {
                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) && this.props.hostConnected &&
                    <ErrorModal
                        title={"Are you ready to play?"}
                        styles={"d-flex"}
                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                        callback={this.closeStartWarning}
                        callbackText={"No"}
                        callback2={this.state.gameState === GameStates.Loading ? this.confirmStartGame : this.confirmPlayAgain}
                        callbackText2={"Yes"}
                    />
                }
                {
                    this.state.showContentWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) && this.props.hostConnected &&
                    <ErrorModal
                        title={"Content Warning 17+"}
                        styles={"d-flex"}
                        message={"You have selected to play with 17+ content. This content is not suitible for anyone under the age of 17 and you may also find some of the content offensive. Are you sure you wish to continue?"}
                        callback={this.closeContentWarning}
                        callbackText={"Back"}
                        callback2={this.confirmContentWarning}
                        callbackText2={"Continue"}
                    />
                }
                {/*<div className={styles.playerSection}>*/}
                {/*    {*/}
                {/*        this.state.player ?*/}
                {/*            <React.Fragment>*/}
                {/*                <div id="potato" className={styles.potato}>*/}
                {/*                    <Lottie*/}
                {/*                        options={avatars[this.state.player.avatar].idleAnim}*/}
                {/*                        width="100%"*/}
                {/*                        height="100%"*/}
                {/*                        isClickToPauseDisabled={true}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className={styles.text}>{this.state.player.name}</div>*/}
                {/*                <div className={`${styles.text} ${styles.score}`}>Score: {this.state.player.scrawlData.score}</div>*/}
                {/*            </React.Fragment>*/}
                {/*            :*/}
                {/*            null*/}
                {/*    }*/}
                {/*</div>*/}
                {/*<div className={styles.button} onClick={this.doAnimation}>Potato Dance!</div>*/}
                {/*<div className={styles.contentSection}>*/}
                {/*    <div className={styles.title}>{this.props.title}</div>*/}
                {/*    <div className={styles.content}>{this.props.content}</div>*/}
                {/*    {*/}
                {/*        this.props.showGifButton ?*/}
                {/*            <div className={styles.button} onClick={() => this.createGIF(this.props.gifImages, this.props.gifName)}>Download GIF</div>*/}
                {/*            :*/}
                {/*            null*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        )
    }

}