import React, { Component, useRef } from 'react';
import * as Colyseus from "colyseus.js";
import { Route, Prompt } from 'react-router';
import { ReactSketchCanvas, } from "react-sketch-canvas";
import { CirclePicker } from "react-color";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import * as gifShot from "gifshot";
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";

import EraserImg from 'images/eraser.png';
import PenImg from 'images/pen.png';
import BucketImg from 'images/bucket.png';
import UndoImg from 'images/undo.png';

import Loading from "components/Loading";
import ClientContent from "components/ClientContent";

import constants from "constants/constants";

import styles from 'components/ClientStyles.module.scss';
import "animate.css";

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    ChoosingCards: "choosing_cards",
    Playing: "playing",
    Revealing: "revealing",
    Awarding: "awarding",
    Idle: "idle",
    Results: "results",
    EndGame: "end_game",
};

const ViewTypes = {
    Default: "_DEFAULT",
    Tutorial: "_TUTORIAL",
    Choices: "_CHOICES",
    Drawing: "_DRAWING",
    Writing: "_WRITING",
    Reveal: "_REVEAL",
    Vote: "_VOTE",
};

const colours = [
    "#000000", "#ffffff", "#617D8B", "#F44336", "#E91E63",
    "#9C27B0", "#673AB7", "#4153B5", "#2196F3", "#03A9F4",
    "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39",
    "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#FFDBAC",
    "#E0AC69", "#C68642", "#8D5524", "#795548",
];

const penSizes = [
    4,
    8,
    12,
    16,
    20,
    24,
];

const gameId = "scrawl";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            connected: false,
            blockNav: true,
            reconnectionToken: "",
            gameState: GameStates.Loading,
            contentFilter: 0,
            availableAddons: [],

            showDefaultView: true,
            showTutorialView: false,
            showChooseOptionsView: false,
            showDrawingView: false,
            showWritingView: false,
            showRevealView: false,
            showChooseBestView: false,

            canvasSize: "",
            canvasBg: "white",
            coloursForBg: false,

            choices: [],
            selectedOption: {},
            penColour: "#000000",
            penSize: 4,
            eraseMode: false,
            showColours: false,
            showPens: false,
            playerWriting: "",
            isPaused: false,

            revealingStage: false,
            isRevealer: false,
            revealTitle: "",
            currentBest: {},

            showPrompt: false,
            endGame: false,
            submitEnabled: false,
            showTimer: false,
            roundTimer: 0,
            timerOptions: timerTurning,

            player: null,
            title: "",
            content: "",

            votedSkip: false,
            forceVotedSkip: false,
            currentPaths: [],
            showGifButton: false,
            gifImages: [],
            gifName: "scrawl-gif",
            gif: null,

            gotLocationPing: true,
            connectionIssue: false,
            isSubmitting: false,
            players: [],
            currentRevealId: "",

            primaryPlayer: false,
            hostConnected: false,

            //room: true,
            //connected: true,
            //player: {
            //    name: "SCOTT",
            //    avatar: 3,
            //},
            //choices: [
            //    {
            //        description: "You scratch my back, I'll scratch yours",
            //        id: "1",
            //    },
            //    {
            //        description: "Washing someone's mouth out with soap",
            //        id: "2",
            //    }
            //],
            //drawDescription: "You scratch my back, I'll scratch yours",
            //submitEnabled: true,
            //writeImg: "https://big-potato-tv.s3.eu-west-2.amazonaws.com/ScrawlDrawings/local/02720ad6-9ce4-42cb-a063-0c02a3a68b03/5f76c135-039c-40f3-a97e-86e89c7374c4",   
            //revealTitle: "Click 'Reveal' to show the next card...",
            //currentBest: {byId: 2,},
        };
        this.locationCheckInterval = null;
        this.canvas = React.createRef();
        this.clientContent = React.createRef();

        //window.onbeforeunload = () => {
        //    if (!this.state.redirect) {
        //        if (this.state.room) {
        //            this.state.room.leave(false);
        //        }
        //    }
        //}
    }

    async componentDidMount() {

        this.setTags();

        this.setState({ canvasSize: this.getCanvasSize(), });

        if (!this.state.room) {
            setTimeout(() => {
                this.doReconnect();
            }, 1500);
        }

        //this.setView(ViewTypes.Reveal);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        document.addEventListener('visibilitychange', (event) => {
            if (document.visibilityState === 'visible') {
                console.log("Visibility changed to visible :)");
            } else {
                console.log("Visibility changed to hidden :(");
            }
        });
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    displayChoices(choices) {
        if (supportsVibrate) navigator.vibrate(500);
        this.setState({ choices, });
        localStorage.removeItem(`${this.state.myId}-CanvasPaths`);
        this.setView(ViewTypes.Choices);
    }

    async handleNextTurn() {
        try {
            if (supportsVibrate) navigator.vibrate(500);
            if (this.state.player.scrawlData.turnIsDrawing) {
                if (!this.drawingInterval) {
                    this.drawingInterval = setInterval(async () => {
                        await this.storePaths();
                    }, 1000);
                }
                this.setState({ canvasBg: "white", isSubmitting: false, });
                this.setView(ViewTypes.Drawing);

                //console.log("canvas paths : " + canvasPaths);
                console.log("Fetching paths from local storage...");
                let paths = localStorage.getItem(`${this.state.myId}-CanvasPaths`);
                if (paths != null) {
                    paths = JSON.parse(paths);
                    console.log("Local storage paths are not null : ", paths);

                    this.canvas.current.loadPaths(paths);
                }
            } else {
                this.setView(ViewTypes.Writing);
            }
            setTimeout(() => {
                this.setState({ submitEnabled: true, });
            }, 2000);
        } catch (e) {
            console.log(e);
        }
    }

    chooseOption(option) {
        this.setState({ selectedOption: option });
    }

    submitChoice = () => {
        if (this.state.selectedOption && this.state.selectedOption.id) {
            this.state.room.send("chose_option", { option: this.state.selectedOption });
            this.setState({ choices: [], });
            //this.setView(ViewTypes.Drawing);
        }
    }

    handleColourChange = (color, event) => {
        if (this.state.coloursForBg) {
            this.setState({ canvasBg: color.hex, showColours: false, });
        } else {
            this.setState({ penColour: color.hex, showColours: false, eraseMode: false, });
            this.canvas.current.eraseMode(false);
        }
    }

    handlePenSizeChange = (size) => {
        this.setState({ penSize: size, showPens: false, });
    }

    toggleErase = () => {
        let value = this.state.eraseMode ? false : true;
        this.setState({ eraseMode: value, });
        this.canvas.current.eraseMode(value);
    }

    undoDraw = () => {
        this.canvas.current.undo();
    }

    redoDraw = () => {
        this.canvas.current.redo();
    }

    async submitDrawing(force = false) {
        if (!this.state.isSubmitting) {
            let paths = await this.canvas.current.exportPaths().then(data => {
                return data;
            });
            console.log("drawing paths : " + JSON.stringify(paths));
            if (paths.length > 0) {
                this.setState({ isSubmitting: true, });

                let drawingURL = await this.canvas.current.exportImage("png").then(data => {
                    return data;
                });

                this.state.room.send("submit_drawing", { imgUrl: drawingURL, force });
                localStorage.removeItem(`${this.state.myId}-CanvasPaths`);
                this.setState({ submitEnabled: false, currentPaths: [], isSubmitting: false, });
                this.setView(ViewTypes.Default);
                if (this.drawingInterval) {
                    clearInterval(this.drawingInterval);
                    this.drawingInterval = null;
                }
            } else if (force) {
                this.state.room.send("submit_empty", { force });
                this.setState({ submitEnabled: false, currentPaths: [], });
                this.setView(ViewTypes.Default);
                if (this.drawingInterval) {
                    clearInterval(this.drawingInterval);
                    this.drawingInterval = null;
                }
            }
        }
    }

    updateWriting = (event) => {
        this.setState({ playerWriting: event.target.value, });
    }

    submitWriting(force = false) {
        if (this.state.playerWriting != null && this.state.playerWriting !== "" && this.state.playerWriting.length > 0) {
            this.state.room.send("submit_writing", { description: this.state.playerWriting, force });
            this.setState({ playerWriting: "", submitEnabled: false, });
            this.setView(ViewTypes.Default);
        } else if (force) {
            this.state.room.send("submit_empty", {});
            this.setState({ playerWriting: "", submitEnabled: false, force });
            this.setView(ViewTypes.Default);
        }
    }

    doStartReveal() {
        if (supportsVibrate) navigator.vibrate(500);
        //this.updateStory(player.scrawlData.story);
        this.setState({ revealTitle: "Click 'Reveal' to show the next card...", });
        this.setView(ViewTypes.Reveal);
    }

    showPlayerRevealing(player) {
        if (player) {
            let contentText = `is revealing their story...`;
            let title = `${player.name}`;
            this.setState({ content: contentText, title: title, revealingStage: true, });
            this.setView(ViewTypes.Default);
        }
    }

    showPlayerAwarding(player) {
        if (player) {
            let contentText = `is choosing their favorite card...`;
            let title = `${player.name}`;
            this.setState({ content: contentText, title: title, revealingStage: true, });
            this.setView(ViewTypes.Default);
        }
    }

    sendRevealNext = () => {
        this.state.room.send("reveal_next", {});
    }

    doAllRevealed() {
        if (supportsVibrate) navigator.vibrate(500);
        this.setState({ revealTitle: "Choose your favourite card and hit Submit!" });
        this.setView(ViewTypes.Vote);
    }


    chooseBest(item) {
        if (item.byId != this.state.myId && this.state.showChooseBestView && item.selectable) {
            this.setState({ currentBest: item, });
        }
    }

    submitBest = () => {
        if (this.state.currentBest.byId != null) {
            this.state.room.send("choose_best", { item: this.state.currentBest, });
            this.stopReveal();
        }
    }

    stopReveal() {
        this.setState({ currentBest: {}, revealTitle: "", });
        this.setView(ViewTypes.Default);
    }



    resetGame() {
        this.setState({
            choices: [],
            selectedOption: {},
            penColour: "#000000",
            penSize: 4,
            eraseMode: false,
            playerWriting: "",
            revealingStage: false,
            titleText: "",
            revealTitle: "",
            currentBest: {},
            endGame: false,
            submitEnabled: false,
            showGifButton: false,
            gifImages: [],
            gifName: "scrawl-gif",
            gif: null,
            title: ""
        });
    }

    setView(viewType) {
        switch (viewType) {
            case ViewTypes.Tutorial:
                this.setState({ showDefaultView: false, showTutorialView: true, showChooseOptionsView: false, showDrawingView: false, showWritingView: false, showRevealView: false, showChooseBestView: false, });
                break;
            case ViewTypes.Choices:
                this.setState({ showDefaultView: false, showTutorialView: false, showChooseOptionsView: true, showDrawingView: false, showWritingView: false, showRevealView: false, showChooseBestView: false, });
                break;
            case ViewTypes.Drawing:
                this.setState({ showDefaultView: false, showTutorialView: false, showChooseOptionsView: false, showDrawingView: true, showWritingView: false, showRevealView: false, showChooseBestView: false, });
                break;
            case ViewTypes.Writing:
                this.setState({ showDefaultView: false, showTutorialView: false, showChooseOptionsView: false, showDrawingView: false, showWritingView: true, showRevealView: false, showChooseBestView: false, });
                break;
            case ViewTypes.Reveal:
                this.setState({ showDefaultView: false, showTutorialView: false, showChooseOptionsView: false, showDrawingView: false, showWritingView: false, showRevealView: true, showChooseBestView: false, });
                break;
            case ViewTypes.Vote:
                this.setState({ showDefaultView: false, showTutorialView: false, showChooseOptionsView: false, showDrawingView: false, showWritingView: false, showRevealView: false, showChooseBestView: true, });
                break;
            default:
                this.setState({ showDefaultView: true, showTutorialView: false, showChooseOptionsView: false, showDrawingView: false, showWritingView: false, showRevealView: false, showChooseBestView: false, });
                break;
        }
    }

    async doCatchup(gameState = null) {
        const player = { ...this.state.player };
        if (!player) return;

        switch (gameState || this.state.gameState) {
            case GameStates.ChoosingCards:
                if (this.state.choices.length == 0 && !player.scrawlData.ready) {
                    //this.displayChoices(data.choices);
                    this.state.room.send("request_choices", {});
                } else if (player.scrawlData.ready) {
                    this.setView(ViewTypes.Default);
                }
                break;
            case GameStates.Tutorial:
                if (!this.state.votedSkip && this.state.forceVotedSkip == false) {
                    this.setView(ViewTypes.Tutorial);
                } else {
                    this.setView(ViewTypes.Default);
                }
                break;
            case GameStates.Playing:
                if (!this.state.showDrawingView && !this.state.showWritingView && !player.scrawlData.ready && player.scrawlData.isPlaying) {
                    await this.handleNextTurn()
                } else if (player.scrawlData.ready && player.scrawlData.isPlaying) {
                    this.setView(ViewTypes.Default);
                } else if (!player.scrawlData.isPlaying) {
                    this.setView(ViewTypes.Default);
                }
                break;
            case GameStates.Revealing:
                if (this.state.isRevealer && !this.state.showRevealView) {
                    this.doStartReveal();
                } else if (!this.state.isRevealer) {
                    this.showPlayerRevealing(this.state.players[this.state.currentRevealId]);
                }
                break;
            case GameStates.Awarding:
                if (this.state.isRevealer && !this.state.showChooseBestView) {
                    this.doAllRevealed();
                } else if (!this.state.isRevealer) {
                    this.showPlayerAwarding(this.state.players[this.state.currentRevealId]);
                }
                break;
            default:
                this.setView(ViewTypes.Default);
                break;
        }
    }

    getCanvasSize = () => {
        let winWidth = window.innerWidth;
        let value;
        if (winWidth > 525) {
            value = "500px";
        } else {
            value = `${winWidth - 25}px`;
        }
        console.log("getting size : " + value);
        return value;
    }

    showColours(forBg = false) {
        let value = this.state.showColours ? false : true;
        this.setState({ showColours: value, showPens: false, coloursForBg: forBg, });
    }

    showPens = () => {
        let value = this.state.showPens ? false : true;
        this.setState({ showPens: value, showColours: false, });
    }

    async forceComplete() {
        if (this.state.player.scrawlData.isPlaying && this.state.player.scrawlData.ready == false) {
            if (this.state.showDrawingView === true) {
                await this.submitDrawing(true);
            } else {
                this.submitWriting(true);
            }
        }
    }

    goToLobby() {
        this.setState({ blockNav: false, });
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    async storePaths() {
        try {
            if (this.canvas.current) {
                let paths = await this.canvas.current.exportPaths().then(data => {
                    return data;
                });
                if (paths.length > this.state.currentPaths.length) {
                    this.setState({ currentPaths: paths, });
                    // store in local storage
                    console.log("Storing Paths in local storage : ", paths);
                    localStorage.setItem(`${this.state.myId}-CanvasPaths`, JSON.stringify(paths));
                    //this.state.room.send("store_paths", { canvasPaths: paths, });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }


    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ votedSkip: true, forceVotedSkip: true, });
        this.setView(ViewTypes.Default);
    }

    createGIF(images, name = "scrawlGif") {
        gifShot.createGIF({
            images,
            interval: 1.5,
            gifWidth: 1200,
            gifHeight: 800,
        }, (obj) => {
            if (!obj.error) {
                this.setState({ gif: obj.image });

                //var image = obj.image;
                //var link = document.getElementById("gifButton");

                //link.setAttribute('href', image)
                //link.setAttribute('download', `${name}.gif`);
                //var image = obj.image;
                //var link = document.createElement("a");

                //link.setAttribute('href', image)
                //link.setAttribute('download', `${name}.gif`);

                ////link.download = `${name}.gif`;
                ////link.target = "_blank";
                ////link.href = image;

                //document.body.appendChild(link);
                //link.click();
                //document.body.removeChild(link);

                //this.setState({ gifButtonEnabled: true, });
            } else {
                this.state.room.send("gif_error", { error: `CODE=${obj.errorCode}.... ${obj.errorMsg}`, });
            }
        })
    };

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }

        try {
            throw new Error('');
        } catch (error) {
            // throwing Error event for Sentry logging, 
            // debugging where redirects are initialized from
        }

        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    updatePlayerProperty(property, value) {
        let player = { ...this.state.player };
        player.scrawlData[property] = value;
        this.setState({ player, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    async doReconnect() {
        const token = this.getQueryStringValue("token");

        if (this.state.connected == false) {
            await this.client.reconnect(token).then(room => {
                console.log(room.sessionId, "joined", room.name);


                this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken, });
                this.updateToken(room.reconnectionToken);
                room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

                room.onStateChange.once((state) => {
                    console.log("this is the first room state!", state);
                    const player = state.players[this.state.myId];
                    if (!player) window.location = this.getRedirectURL();
                    Sentry.setUser({ id: player.uniqueId });

                    this.setState({
                        roomState: state,
                    });
                    this.startLocationChecks();
                });
                room.onStateChange((state) => {
                    console.log(room.name, "has new state:", state);
                    this.setState({
                        roomState: state,
                    });
                });

                room.state.availableAddons.onAdd((addon, key) => {
                    let availableAddons = [...this.state.availableAddons];
                    availableAddons.push(addon);
                    this.setState({ availableAddons });
                });

                room.state.players.onAdd((player, key) => {
                    this.checkAndAddPlayer(player);
                    player.listen("connected", (currentValue, previousValue) => {
                        let statePlayers = [...this.state.players];
                        let index = statePlayers.findIndex(elem => elem.id === player.id);
                        statePlayers[index].connected = currentValue;
                        this.setState({ players: statePlayers });
                    });

                    if (player.id === room.sessionId) {
                        this.setState({ player: player, });

                        player.listen("votedSkip", (currentValue, previousValue) => {
                            this.setState({ votedSkip: currentValue, });
                        });
                        player.listen("primaryPlayer", (currentValue, previousValue) => {
                            this.setState({ primaryPlayer: currentValue, });
                        });

                        if (player.scrawlData) {
                            player.scrawlData.onChange(() => {
                                this.doCatchup();
                            });
                            player.scrawlData.story.onAdd((element, index) => {
                                console.log("story onAdd : ", element);
                                let statePlayer = { ...this.state.player };
                                statePlayer.scrawlData.story[index] = element;
                                this.setState({ player: statePlayer });
                            });
                            player.scrawlData.story.onRemove((element, index) => {
                                console.log("story onAdd : ", element);
                                let statePlayer = { ...this.state.player };
                                statePlayer.scrawlData.story.splice(index, 1);
                                this.setState({ player: statePlayer });
                            });
                            player.scrawlData.currentChoicesIds.onChange((value, index) => {
                                let statePlayer = { ...this.state.player };
                                statePlayer.scrawlData.currentChoicesIds[index] = value;
                                this.setState({ player: statePlayer });
                            })
                            player.scrawlData.listen("isPlaying", (currentValue, previousValue) => {
                                this.updatePlayerProperty("isPlaying", currentValue);
                                if (currentValue === true) {
                                    this.setState({ title: "", });
                                } else {
                                    this.setState({ title: "SPECTATING", });
                                }
                            });
                            player.scrawlData.listen("answer", (currentValue, previousValue) => {
                                this.updatePlayerProperty("answer", currentValue);
                            });
                            player.scrawlData.listen("score", (currentValue, previousValue) => {
                                this.updatePlayerProperty("score", currentValue);
                            });
                            player.scrawlData.listen("cardId", (currentValue, previousValue) => {
                                this.updatePlayerProperty("cardId", currentValue);
                            });
                            player.scrawlData.listen("cardText", (currentValue, previousValue) => {
                                this.updatePlayerProperty("cardText", currentValue);
                            });
                            player.scrawlData.listen("ready", (currentValue, previousValue) => {
                                this.updatePlayerProperty("ready", currentValue);
                            });
                            player.scrawlData.listen("forId", (currentValue, previousValue) => {
                                this.updatePlayerProperty("forId", currentValue);
                            });
                            player.scrawlData.listen("turnData", (currentValue, previousValue) => {
                                console.log("turnData change detected : ", currentValue);
                                this.updatePlayerProperty("turnData", currentValue);
                            });
                            player.scrawlData.listen("turnIsDrawing", (currentValue, previousValue) => {
                                console.log("turnIsDrawing change detected : ", currentValue);
                                this.updatePlayerProperty("turnIsDrawing", currentValue);
                            });
                        }
                    }
                });

                room.state.scrawlData.listen("contentFilter", (currentValue) => {
                    this.setState({ contentFilter: currentValue });
                    this.clientContent.current.changeContentFilter(currentValue, false);
                });

                room.state.host.listen("connected", (value) => {
                    this.setState({ hostConnected: value });
                });

                room.state.listen("isPaused", (currentValue, previousValue) => {
                    this.setState({ isPaused: currentValue, });
                });
                room.state.scrawlData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                    this.doCatchup(currentValue);
                });
                room.state.scrawlData.listen("currentRevealId", (currentValue, previousValue) => {
                    console.log(`currentRevealId change detected : ${currentValue}`);
                    const isRevealer = currentValue === this.state.myId;
                    this.setState({ isRevealer, currentRevealId: currentValue });
                    this.doCatchup();
                });

                room.onMessage("end_tutorial", (message) => {
                    console.log("end_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Default);
                });
                room.onMessage("start_playing", (message) => {
                    console.log("start_playing", "received on", room.name, message);

                });
                room.onMessage("get_choices", (message) => {
                    console.log("get_choices", "received on", room.name, message);
                    this.displayChoices(message.choices);
                });
                room.onMessage("next_turn", async (message) => {
                    console.log("next_turn", "received on", room.name, message);
                    await this.handleNextTurn();
                });
                //room.onMessage("continue_turn", async (message) => {
                //    console.log("continue_turn", "received on", room.name, message);
                //    await this.handleNextTurn(message);
                //});
                room.onMessage("round_over", (message) => {
                    console.log("round_over", "received on", room.name, message);
                    this.setState({ playerWriting: "", });
                    this.setView(ViewTypes.Default);
                });
                room.onMessage("start_reveal", (message) => {
                    console.log("start_reveal", "received on", room.name, message);
                    this.setState({ showGifButton: false, gifImages: [], gifName: "scrawl-gif", });
                    if (message.currentRevealId === this.state.myId) {
                        this.setState({ content: "", title: "", revealingStage: true, });
                        this.doStartReveal();
                    } else {
                        this.showPlayerRevealing(message.player);
                    }
                });
                //room.onMessage("reveal_next", (message) => {
                //    console.log("reveal_next", "received on", room.name, message);
                //    if (this.state.showRevealView) {
                //        this.updateStory(message.story);
                //    }
                //});
                room.onMessage("all_revealed", (message) => {
                    console.log("all_revealed", "received on", room.name, message);
                    if (message.player.id == this.state.myId) {
                        //this.updateStory(message.story, true);
                        this.doAllRevealed();
                    } else {
                        this.showPlayerAwarding(message.player);
                    }
                });
                //room.onMessage("story_vote", (message) => {
                //    console.log("story_vote", "received on", room.name, message);
                //    if (this.state.myId != message.id) {
                //        this.doStoryVote();
                //    }
                //});
                room.onMessage("change_game", (message) => {
                    console.log("change_game", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("game_over", (message) => {
                    console.log("game_over", "received on", room.name, message);
                    if (this.state.primaryPlayer) {
                        this.setState({ endGame: true, content: "", revealingStage: false,  title: "", });
                    } else {
                        this.setState({ endGame: true, content: "", revealingStage: false, title: "Waiting for host..." });
                    }
                });

                room.onMessage("new_game", (message) => {
                    console.log("new_game", "received on", room.name, message);
                    this.resetGame();
                });
                room.onMessage("force_complete", async (message) => {
                    console.log("force_complete", "received on", room.name, message);
                    await this.forceComplete();
                });
                room.onMessage("force_choose_card", (message) => {
                    console.log("force_choose_card", "received on", room.name, message);
                    this.setState({ choices: [], });
                    this.setView(ViewTypes.Default);
                });
                room.onMessage("no_best", (message) => {
                    console.log("no_best", "received on", room.name, message);
                    this.stopReveal();
                });
                room.onMessage("choose_best", (message) => {
                    console.log("choose_best", "received on", room.name, message);
                    this.stopReveal();
                });
                room.onMessage("host_joined_lobby", (message) => {
                    console.log("host_joined_lobby", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("game_starting", (message) => {
                    console.log("game_starting", "received on", room.name, message);
                    if (message.gameId != gameId) {
                        this.goToLobby();
                    }
                });
                room.onMessage("update_timer", (message) => {
                    console.log("update_timer", "received on", room.name, message);
                    //if (message.count <= 10) {
                    if (message.count <= 5) {
                        if (message.count <= 0) {
                            setTimeout(() => {
                                this.setState({ showTimer: false, });
                            }, 2000);
                        }
                        this.setState({ timerOptions: timerEnd });
                    } else {
                        this.setState({ timerOptions: timerTurning });
                    }
                    this.setState({ roundTimer: message.count, showTimer: false, });
                    //} else {
                    //    this.setState({ showTimer: false, });
                    //}
                });
                room.onMessage("gif_ready", (message) => {
                    console.log("gif_ready", "received on", room.name, message);

                    this.createGIF(message.images, message.gifName);

                    this.setState({ showGifButton: true, gifImages: message.images, gifName: message.gifName });
                });

                room.onMessage("show_tutorial", (message) => {
                    console.log("show_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Tutorial);
                });

                room.onMessage("begin_tutorial", (message) => {
                    console.log("begin_tutorial", "received on", room.name, message);
                });
                room.onMessage("toggle_pause", (message) => {
                    console.log("toggle_pause", "received on", room.name, message);
                    this.setState({ isPaused: message.pause });
                });

                room.onMessage("location_confirmed", (message) => {
                    console.log("location_confirmed", "received on", room.name, message);
                    this.setState({ gotLocationPing: true, });
                });

                room.onError((code, message) => {
                    console.log(this.client.id, "couldn't join", room.name);
                    Sentry.captureMessage(`WS Received: onError`);
                    //LoggingService.logError(message, code);
                });
                room.onLeave(async (code) => {
                    console.log(this.client.id, "left", room.name);
                    // Sentry.captureMessage('Websocket disconnected: ' + (typeof constants.WSErrorCodes[code] !== 'undefined' ? constants.WSErrorCodes[code] : code), 'error');
                    this.setState({ connected: false, });

                    if (!this.state.redirectURL) {
                        if (code == 4050) {
                            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                        } else {
                            await this.doReconnect();
                        }
                    } else {
                        setTimeout(() => {
                            this.setState({ redirect: true, });
                        }, 1500);
                    }
                });
            }).catch(e => {
                console.log("JOIN ERROR", e);
                this.setState({ blockNav: false, });
                this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                const message = e.message ? e.message : "An error occured joining Scrawl.";
                Sentry.captureMessage(`doReconnect Error: ${message}`);
                //LoggingService.logError(message, e);
                if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
            });
        }
    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" room={this.state.room} className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                <div id="clientContainer" room={this.state.room} className={`${styles.clientContainer} ${this.state.contentFilter && styles.adults}`}>

                    {
                        this.state.room ?
                            this.state.connected ?
                                <React.Fragment>
                                    {/*<Prompt*/}
                                    {/*    when={[GameStates.Loading, GameStates.EndGame].includes(this.state.gameState)}*/}
                                    {/*    message='Are you sure you want to leave?'*/}
                                    {/*/>*/}
                                    {
                                        this.state.connectionIssue &&
                                        <div className={styles.connectionIssueContainer}>
                                            <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                            <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                        </div>
                                    }
                                    {
                                        this.state.isPaused &&
                                        <div className={styles.pauseContainer}>
                                            <div className={styles.pauseText}>Paused</div>
                                        </div>
                                    }
                                    {
                                        this.state.showDefaultView &&
                                        <ClientContent ref={this.clientContent}
                                            room={this.state.room}
                                            player={this.state.player}
                                            title={this.state.title}
                                            content={this.state.content}
                                            isRevealing={this.state.revealingStage}
                                            showGifButton={this.state.showGifButton}
                                            gifImages={this.state.gifImages}
                                            gifName={this.state.gifName}
                                            gif={this.state.gif}
                                            canvasSize={this.state.canvasSize}
                                            hostConnected={this.state.hostConnected}
                                            players={this.state.players}
                                            contentFilter={this.state.contentFilter}
                                            availableAddons={this.state.availableAddons}
                                        />
                                    }
                                    {
                                        this.state.showTutorialView &&
                                        <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                            <div className={styles.skipButton}>Skip Tutorial</div>
                                        </div>
                                    }
                                    {
                                        this.state.showChooseOptionsView &&
                                        <React.Fragment>
                                            <div className={styles.header}>CHOOSE</div>
                                            <div className={styles.title}>What do you want to draw?</div>
                                            <div className={styles.choiceList}>
                                                {
                                                    this.state.choices.map((x) => {
                                                        return <div className={`${styles.choice} ${this.state.selectedOption.id === x.id ? styles.selected : ""}`} onClick={() => this.chooseOption(x)}>{x.description}</div>
                                                    })
                                                }
                                            </div>
                                            <div className={styles.submit}>
                                                <div className={styles.subButton} onClick={this.submitChoice}>Submit</div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showDrawingView && this.state.player.scrawlData.turnData &&
                                        <React.Fragment>
                                            <div className={styles.header}>DRAW</div>
                                            <div className={`${styles.roundTimer} ${this.state.showTimer ? styles.showTimer : ""}`}>
                                                <Lottie options={this.state.timerOptions} width="12vh" height="12vh" isClickToPauseDisabled={true} />
                                                <div className={styles.text}>{this.state.roundTimer}</div>
                                            </div>
                                            <div className={`${styles.title} ${styles.addBg}`}>{this.state.player.scrawlData.turnData}</div>
                                            <ReactSketchCanvas
                                                ref={this.canvas}
                                                style={{
                                                    position: "relative", left: "50%", transform: "translateX(-50%)",
                                                    backgroundColor: this.state.canvasBg, boxShadow: "4px 4px 10px 1px rgba(0,0,0,0.3)",
                                                    minHeight: this.state.canvasSize,
                                                    minWidth: this.state.canvasSize,
                                                }}
                                                canvasColor={this.state.canvasBg}
                                                width={this.state.canvasSize}
                                                height={this.state.canvasSize}
                                                strokeWidth={this.state.penSize}
                                                strokeColor={this.state.penColour}
                                                eraserWidth={this.state.penSize}
                                                id="sketchCanvas"
                                            />
                                            <div className={styles.toolsContainer}>
                                                <div className={styles.toolsInner}>
                                                    <div className={styles.chosenColour} onClick={() => this.showColours()} style={{ backgroundColor: this.state.penColour }}>
                                                        <div className={styles.tab}></div>
                                                    </div>
                                                    <div className={styles.chosenPen} onClick={this.showPens}>
                                                        <div className={styles.innerPen} style={{ width: `${this.state.penSize}px`, height: `${this.state.penSize}px`, }}></div>
                                                    </div>
                                                    <img src={EraserImg} className={`${styles.tool} ${this.state.eraseMode ? styles.toggled : ""}`} onClick={this.toggleErase} />
                                                    <img src={PenImg} className={`${styles.tool} ${this.state.eraseMode ? "" : styles.toggled}`} onClick={this.toggleErase} />
                                                    <img src={BucketImg} className={`${styles.tool}`} onClick={() => this.showColours(true)} />
                                                    <img src={UndoImg} className={`${styles.tool}`} onClick={this.undoDraw} />
                                                    <img src={UndoImg} className={`${styles.tool} ${styles.flipped}`} onClick={this.redoDraw} />
                                                </div>

                                                <CirclePicker
                                                    className={`${styles.colourSection} ${this.state.showColours ? styles.show : ""}`}
                                                    color={this.state.penColour}
                                                    onChangeComplete={this.handleColourChange}
                                                    circleSpacing={4}
                                                    circleSize={27.5}
                                                    colors={colours}
                                                />

                                                <div className={`${styles.penSizeSection} ${this.state.showPens ? styles.show : ""}`}>
                                                    {
                                                        penSizes.map((x) => {
                                                            return <div className={`${styles.penContainer} ${this.state.penSize == x ? styles.show : ""}`} onClick={() => this.handlePenSizeChange(x)}>
                                                                <div
                                                                    className={styles.penSize}
                                                                    style={{ width: `${x}px`, height: `${x}px`, }}
                                                                >
                                                                    <div className={`${styles.innerCircle} ${this.state.penSize == x ? styles.show : ""}`} />
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={`${styles.submit} ${this.state.submitEnabled ? "" : styles.disabled}`}>
                                                <div className={`${styles.subButton}`} onClick={this.state.submitEnabled ? async () => { await this.submitDrawing() } : null}>
                                                    {
                                                        this.state.isSubmitting ?
                                                            <div className={styles.loading}></div>
                                                            :
                                                            "Submit"
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showWritingView && this.state.player.scrawlData.turnData &&
                                        <React.Fragment>
                                            <div className={styles.header}>WRITE</div>
                                            <div className={styles.roundTimer}>{this.state.roundTimer}</div>
                                            <div className={styles.title}>Describe what you see!</div>
                                            <img className={styles.writeImg} src={this.state.player.scrawlData.turnData} />
                                            <input className={styles.writeBox} maxLength={40} placeholder={"Describe what you see!"} onChange={this.updateWriting} />
                                            <div className={`${styles.submit} ${this.state.submitEnabled ? "" : styles.disabled}`}>
                                                <div className={`${styles.subButton}`} onClick={this.state.submitEnabled ? () => this.submitWriting(false) : null} >Submit</div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showRevealView &&
                                        <React.Fragment>
                                            <div className={styles.revealSection}>
                                                <div className={styles.title}>{this.state.revealTitle}</div>
                                                <div className={styles.storyList}>
                                                    {
                                                        this.state.player.scrawlData.story.map(x => {
                                                            return <div className={`${styles.storyItem} ${x.isEmpty ? styles.empty : ""}  ${x.revealed ? styles.hidden : ""}`}>
                                                                {
                                                                    x.isEmpty ?
                                                                        <div className={styles.itemContent + " " + styles.empty}>X</div>
                                                                        :
                                                                        x.isDrawing ?
                                                                            <img className={styles.itemContent} src={x.content} />
                                                                            :
                                                                            <div className={styles.itemContent + " " + styles.text}>{x.content}</div>
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.submit}>
                                                <div className={styles.subButton} onClick={this.sendRevealNext}>Reveal</div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showChooseBestView &&
                                        <React.Fragment>
                                            <div className={styles.revealSection}>
                                                <div className={styles.voteList}>
                                                    {
                                                        this.state.player.scrawlData.story.map(x => {
                                                            return <div className={`${styles.voteItem} ${x.isEmpty ? styles.empty : ""} ${this.state.currentBest.byId == x.byId ? styles.chosen : ""} ${x.selectable ? "" : styles.fade}`} onClick={() => this.chooseBest(x)}>
                                                                {
                                                                    x.isEmpty ?
                                                                        <div className={styles.itemContent + " " + styles.empty}>X</div>
                                                                        :
                                                                        x.isDrawing ?
                                                                            <img className={styles.itemContent} src={x.content} />
                                                                            :
                                                                            <div className={styles.itemContent + " " + styles.text}>{x.content}</div>
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.submit}>
                                                <div className={styles.subButton} onClick={this.submitBest}>Select</div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <Loading loadingText={"Trying to reconnect you..."} noBg={true} hideLoader={false} />
                            :
                            <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                    }
                </div>
            </div>
        );
    }
}
