import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Client } from './components/Client';
import Loading from './components/Loading';
//import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from "@sentry/react";
import { getCookie } from './helpers/cookies';

import constants from './constants/constants';

import 'styles/Site.scss';

//datadogRum.init({
//    applicationId: 'aec6062a-5213-4e24-8f0c-10ea75244fa6',
//    clientToken: 'pub812259580c20c1c3678977ffc0623979',
//    site: 'datadoghq.eu',
//    service: 'scrawl',
//    env: 'Production',
//    // Specify a version number to identify the deployed version of your application in Datadog
//    // version: '1.0.0', 
//    sessionSampleRate: 100,
//    sessionReplaySampleRate: 100,
//    trackUserInteractions: true,
//    trackResources: true,
//    trackLongTasks: true,
//    defaultPrivacyLevel: 'mask-user-input',
//});

Sentry.init({
    dsn: "https://f66f6ef8d374255096fe2e254646ed1f@o4507028880883712.ingest.us.sentry.io/4507050506977280",
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'undefined',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/scrawlweb-stage\.azurewebsites\.net/, /^https:\/\/scrawl\.game\.city/],
    // Session Replay
    replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (getCookie('uniqueId')) {
    Sentry.setUser({ id: getCookie('uniqueId') });
}


/*
Sentry.captureMessage('This is a test message (debug)', 'debug');
Sentry.captureMessage('This is a test message (warning)', 'warning');
Sentry.captureMessage('This is a test message (error)', 'error');
Sentry.captureMessage('This is a test message (fatal)', 'fatal');
Sentry.captureMessage('This is a test message (info)', 'info');
Sentry.captureMessage('This is a test message (log)', 'log');
*/

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            percentage: 0,
        };
    }

    componentDidMount() {
        let percentage = this.state.percentage;
        let percentPerItem = 100 / (constants.fonts.length + constants.images.length);
        // Preload images
        setTimeout(() => {
            Promise.all(constants.images.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image;
                }).then(() => {
                    percentage += percentPerItem;
                    this.setState({ percentage });
                });
            })).then(() => {
                // Preload fonts
                return Promise.all(constants.fonts.map(font => {
                    return new Promise((resolve, reject) => {
                        const fontFace = new FontFace(font.font, `url(${font.path})`);
                        fontFace.load().then(() => {
                            document.fonts.add(fontFace);
                            percentage += percentPerItem;
                            this.setState({ percentage });
                            resolve();
                        }).catch(reject);
                    });
                }));
            }).then(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 1500);
                console.log('Assets preloaded!');
            }).catch(error => {
                // if there's an error, we still want to show the app, so set isLoading to false
                this.setState({ isLoading: false });
                console.error('Error preloading resources:', error);
            });
        }, 1500);
    }

    render() {
        return (
            <Layout>
                {
                    this.state.isLoading ?
                        <Loading loadingText={""} progress={this.state.percentage} />
                        :
                        <>
                            <Route exact path='/' component={Home} />
                            <Route path='/client' component={Client} />
                        </>
                }
            </Layout>
        );
    }
}
