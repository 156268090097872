import background from "../images/background.png";
import backgroundGreen from "../images/background-green.png";
import banner from "../images/banner.png";
import bucket from "../images/bucket.png";
import cardsImg from "../images/cards-img.png";
import cardsImgOld from "../images/cards-img-old.png";
import eraser from "../images/eraser.png";
import frame from "../images/frame.png";
import muted from "../images/muted.png";
import painter from "../images/painter.png";
import pen from "../images/pen.png";
import potato from "../images/potato.png";
import scrawlFullscreen from "../images/scrawl_fullscreen.png";
import scrawlHelp from "../images/scrawl_help.png";
import scrawlMuted from "../images/scrawl_muted.png";
import scrawlUnmuted from "../images/scrawl_unmuted.png";
import scrawlLogo from "../images/scrawl-logo.png";
import scrawlLogo17 from "../images/scrawl-logo-17.png";
import undo from "../images/undo.png";
import unmuted from "../images/unmuted.png";
import familyIcon from "images/Family Icon.png"
import adultsIcon from "images/Adults Icon.png"


const images = [
background,
    banner,
    bucket,
    cardsImg,
    cardsImgOld,
    eraser,
    frame,
    muted,
    painter,
    pen,
    potato,
    scrawlFullscreen,
    scrawlHelp,
    scrawlMuted,
    scrawlUnmuted,
    scrawlLogo,
    undo,
    unmuted,
    familyIcon,
    adultsIcon,
    backgroundGreen,
    scrawlLogo17
];

const fonts = [
    { font: "Gagalin", path: `https://${window.location.host}/Assets/gagalin.ttf`},
    { font: "Helenita", path: `https://${window.location.host}/Assets/HelenitaBookRough.otf`},
    { font: "Interstate", path: `https://${window.location.host}/Assets/Interstate-Regular.otf`},
    { font: "MyriadPro", path: `https://${window.location.host}/Assets/MyriadPro-Regular.otf`},
];


const WSErrorCodes = {
    1001: "Browser Tab Closing",
    1002: "Malformed framce received",
    1003: "Unsupported frame received",
    1006: "GS ungraceful shutdown / connection refused",
    1011: "Internal Error",
    1012: "Server Restarting",
    1013: "Try again later, server capacity full",
    1014: "Bad Gateway",
    4000: "Gameserver graceful shutdown",
    4050: "Player removed"
};

export default {
    images,
    fonts,
    WSErrorCodes
};