import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";


export default class Player extends Component {

    constructor(props) {
        super(props);
        let fontSize = this.getFontSize(props.player);
        this.state = {
            fontSize: fontSize,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ fontSize: this.getFontSize(nextProps.player), });
    }


    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    render() {
        return (
            this.props.isEmpty ?
                <div className={`${styles.player} ${this.props.left ? "" : styles.reverse}`} />
                :
                <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.hide ? styles.hidden : ""} ${this.props.left ? "" : styles.reverse}  ${this.props.winner ? styles.winner : ""} ${this.props.player.scrawlData.isPlaying ? "" : styles.shrink}`}>
                    {
                        this.props.player.scrawlData.isPlaying ?
                            null
                            :
                            <div className={styles.spectatingText}>Spectating...</div>
                    }
                    <div className={`${styles.playerSection}`} style={{ backgroundColor: this.props.colour }}>
                        <div className={`${styles.number} ${this.props.left ? styles.left : styles.right}`}>{this.props.player.scrawlData.score}</div>
                        {/*<div className={`${styles.number} ${this.props.left ? styles.left : styles.right}`}>{this.props.number}</div>*/}
                        {
                            this.props.winner ?
                                <div className={`${styles.winnerName} ${this.state.fontSize == "large" ? styles.large : this.state.fontSize == "medium" ? styles.medium : styles.small}`}>{this.props.player.name}</div>
                                :
                                null
                        }
                        <div
                            id={`potato-${this.props.player.id}`}
                            className={`${styles.potato} ${this.props.left ? styles.right : styles.left}`}
                        >
                            <Lottie
                                options={getAvatarById(this.props.player.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </div>
                    {
                        this.props.winner ?
                            null
                            :
                            <div className={`${styles.textSection}`}>
                                <div id="nameText" className={`${styles.nameText} ${this.state.fontSize == "large" ? styles.large : this.state.fontSize == "medium" ? styles.medium : styles.small}`}>{this.props.player.name}</div>
                                {/*<div id="scoreText" className={styles.nameText}>{this.props.player.scrawlData.score}</div>*/}
                            </div>
                    }
                    <div className={`${styles.answeredText} ${this.props.left ? styles.left : styles.right} ${this.props.player.scrawlData.ready ? styles.show : ""}`}>Ready</div>
                </div>
        )
    }
}